import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./carousel.css";
import { ArrowBigLeft, ArrowBigRight } from "lucide-react";

const Carousel = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);
  const handleNext = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel-container relative rounded-xl overflow-hidden">
      {/* Images */}
      {images.map((image, index) => (
        <motion.div
          key={index}
          className="carousel-image absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${image})`,
            opacity: currentImage === index ? 1 : 0,
            zIndex: currentImage === index ? 1 : 0,
          }}
        ></motion.div>
      ))}

      {/* Navigation Arrows */}
      <button
        className="prev-button absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-800 bg-white rounded-full p-2 z-10 hidden"
        onClick={handlePrev}
      >
        <ArrowBigLeft />
      </button>
      <button
        className="next-button absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-800 bg-white rounded-full p-2 z-10 hidden"
        onClick={handleNext}
      >
        <ArrowBigRight />
      </button>

      {/* Indicators */}
      <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 flex gap-2 z-10 ">
        {images.map((image, index) => (
          <div
            key={index}
            className={` w-5 h-5 rounded-full border-double border-[2px] ${
              currentImage === index ? "bg-green-900" : "bg-white"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
